import React from "react"

import Layout, { Article, ArticleHeader, Section } from "../components/layout"
import SEO from "../components/seo"
import About from "../components/about"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Contact = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Contact Me" />
      <Article>
        <ArticleHeader title="Contact Me" />
        <Section style={{ padding: "0 2em 0 2em" }}>
          <p style={{ marginTop: "5px", textAlign: "center" }}>
            Hey there! If you've landed on this page, chances are you're looking
            for some killer custom design work. Well, you're in luck! I
            specialize in graphic design and illustration - everything from
            logos and beer labels to stickers and T-shirt designs, and so much
            more. Got a wild idea? Let's bring it to life! The best way to reach
            me is by email - let's make something awesome together!
            <span style={{ display: "flex", justifyContent: "center" }}>
              <a className="link" href="mailto:decaffeinateddesigns@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} height={21} />{" "}
                decaffeinateddesigns@gmail.com
              </a>
            </span>
          </p>
        </Section>
      </Article>
      <About />
    </Layout>
  )
}

export default Contact
